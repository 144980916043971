import axios from 'axios';
import qs from 'querystring';

const AUTH_URL = '/authenticate';
const APP_URL = '/';

export const authenticate = (username, password) => {
	return axios.post(AUTH_URL, qs.stringify({ username, password }), {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}).then(() => window.location.replace(APP_URL));
}

export const isDomain = (...subDomain) => {
	const host = window.location.host;
	const splittedHost = host.split(/[.:]+/);
	console.log('splittedHost', splittedHost);
	return splittedHost.length > 0 && subDomain.includes(splittedHost[0])
}

export const getSubDomain= ()=> {
	const [subdomain]= window.location.host.split('.');
	// const query = new URLSearchParams(window.location.search)
	// const tenant = query.get('tenant') || subdomain
	return(subdomain);
};

export const getAssets= ()=> {
	const assets= {
		hasAssets: false,
		bgBottom: '',
		bgColor: '#fff',
		cColor: 'rgba(0, 0, 0, 0.54)',
		bgTop: '',
		logo: '',
		isSafe: false,
		customLogo: null,
		customBackground: null
	};
	switch(getSubDomain()) {
	// switch('rsefram') {
	
		case 'stagingrsblj':
		case 'blj':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/blj/logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/blj/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/blj/bg_top.webp',
					logo: '/static/img/tenant/blj/logo.webp'
				});
			}
			break;
	
		case 'stagingrspm':
		case 'rspm':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rspm/logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rspm/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rspm/bg_top.webp',
					logo: '/static/img/tenant/rspm/logo.webp'
				});
			}
			break;

		case 'stagingrsudl':
		case 'rsudl':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsudl/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsudl/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rsudl/bg_top.webp',
					logo: '/static/img/tenant/rsudl/logo.png'
				});
			}
			break;

		case 'stagingrskb':
		case 'rskb':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rskb/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rskb/bg_bottom.webp',
					bgColor: '#1b8dc7',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rskb/bg_top.webp',
					logo: '/static/img/tenant/rskb/logo.png',
					bgBottomKardex: '/static/img/tenant/rskb/kardex/bg_bottom.webp',
					bgTopKardex: '/static/img/tenant/rskb/kardex/bg_top.webp',
					bgColorKardex: '#1b8dc7'
				});
			}
			break;
	
		case 'stagingrscmc':
		case 'rscmc':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rscmc/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscmc/bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rscmc/bg_top.webp',
					logo: '/static/img/tenant/rscmc/logo.png'
				});
			}
			break;
	
		case 'stagingrspirngadi':
		case 'rspirngadi':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rspirngadi/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rspirngadi/bg_bottom.webp',
					bgTop: '/static/img/tenant/rspirngadi/bg_top.webp',
					logo: '/static/img/tenant/rspirngadi/logo.webp'
				});
			}
			break;
	
		case 'stagingrstobelo':
		case 'rstobelo':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rstobelo/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rstobelo/bg_bottom.webp',
					cColor: '#fff',
					bgTop: '/static/img/tenant/rstobelo/bg_top.webp',
					logo: '/static/img/tenant/rstobelo/logo.webp'
				});
			}
			break;
	
		case 'stagingrsdp':
		case 'rsdp':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsserang/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsserang/bottom_bsre.webp',
					cColor: 'rgba(0, 0, 0, 0.54)',
					bgTop: '/static/img/tenant/rsserang/bg_top.webp',
					logo: '/static/img/tenant/rsserang/logo.png'
				});
			}
			break;

		case 'stagingrsudsayang':
		case 'rsudsayang':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsudsayang/idulfitri/Logo.png',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsudsayang/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsudsayang/bg_top.webp',
					logo: '/static/img/tenant/rsudsayang/new-logo.webp'
				});
			}
			break;
		
		case 'stagingrsam':
		case 'rsam':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsam/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsam/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsam/bg_top.webp',
					logo: '/static/img/tenant/rsam/logo.png'
				});
			}
			break;

		case 'stagingrsefram':
		case 'rsefram':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_v2.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsefram/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsefram/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsefram/bg_top.webp',
					logo: '/static/img/tenant/rsefram/logo_mini.webp'
				});
			}
			break;

		case 'stagingrscibabat':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rscibabat/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscibabat/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rscibabat/bg_top.webp',
					logo: '/static/img/tenant/rscibabat/logo.webp'
				});
			}
			break;
		case 'rscibabat':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rscibabat/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rscibabat/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rscibabat/bg_top.webp',
					logo: '/static/img/tenant/rscibabat/logo.webp'
				});
			}
			break;
			
		case 'stagingrsudtangerang':
		case 'rsudtangerang':
			if(hasSpecialAssets()){
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rstangerang/logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				})
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rstangerang/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rstangerang/bg_top.webp',
					logo: '/static/img/tenant/rstangerang/logo.webp'
				})
			}
			break;
			
		case 'stagingrsbap':
		case 'rsbap':
			if(hasSpecialAssets()){
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsbap/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				})
			}
			else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsbap/bg_bottom_v2.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsbap/bg_top.webp',
					logo: '/static/img/tenant/rsbap/logo.webp'
				})
			}
		break;

		case 'stagingrsannisa':
		case 'rsannisa':
			if(hasSpecialAssets()){
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_v2.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsannisa/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				})
			}
			else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsannisa/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsannisa/bg_top.webp',
					logo: '/static/img/tenant/rsannisa/logo.webp'
				})
			}
		break;
		
		case 'stagingrsudsultanimanuddin':
		case 'rssi':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/pangkalanbun/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/pangkalanbun/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/pangkalanbun/bg_top.webp',
					logo: '/static/img/tenant/pangkalanbun/logo.webp',
					bgColor: '#E0F5FF'
				})
			}
			break;
			
		case 'stagingrsudselong':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/selong/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/demo/bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/demo/top.webp',
					logo: '/static/img/tenant/demo/logo.webp',
					bgColor: '#e2f4fb'
				})
			}
			break;
		case 'emrrsudsoedjono':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/selong/hut/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/tenant/selong/hut/top.webp',
					logo: '/static/img/tenant/selong/logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg',
					customBackground: 'rgba(255, 255, 255, 0.75)'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/selong/bg_bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/selong/bg_top.webp',
					logo: '/static/img/tenant/selong/logo.webp',
					bgColor: '#FCFCE1'
				})
			}
		break;

		case 'stagingrsudparung':
		case 'rsudparung':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom_bsre.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/parung/idulfitri/Logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
				
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/parung/bg_bottom_bsre.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/parung/bg_top.webp',
					logo: '/static/img/tenant/parung/logo.webp',
					bgColor: '#FCFCE1'
				})
			}
			break;
		case 'stagingrsudanuntaloko':
		case 'rsudanuntaloko':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/anuntaloko/logo.png',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/anuntaloko/bottom.png',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/anuntaloko/top.png',
					logo: '/static/img/tenant/anuntaloko/logo.png',
					bgColor: '#fdfce0'
				})
			}
			break;

		case 'stagingrsudmimika':
		case 'rsudmimika':
			if(hasSpecialAssets()) {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/17-agustus/bottom.webp',
					bgColor: '#ffaacb',
					cColor: 'black',
					bgTop: '/static/img/17-agustus/top.webp',
					logo: '/static/img/tenant/rsmimika/logo.webp',
					buttonColor: '#ba0e0e',
					buttonHoverColor: '#ba0e0eb5',
					customLogo: '/static/img/17-agustus/logo_ecal.svg'
				});
			} else {
				Object.assign(assets, {
					hasAssets: true,
					bgBottom: '/static/img/tenant/rsmimika/bottom.webp',
					cColor: 'rgba(255, 255, 255, 0.75)',
					bgTop: '/static/img/tenant/rsmimika/top.webp',
					logo: '/static/img/tenant/rsmimika/logo.webp',
					bgColor: '#c7f5ff'
				})
			}
			break;

    case 'stagingrsudtripat':
    case 'rsudtripat':
      if(hasSpecialAssets()) {
        Object.assign(assets, {
          hasAssets: true,
          bgBottom: '/static/img/17-agustus/bottom.webp',
          bgColor: '#ffaacb',
          cColor: 'black',
          bgTop: '/static/img/17-agustus/top.webp',
          logo: '/static/img/tenant/tripat/logo.webp',
          buttonColor: '#ba0e0e',
          buttonHoverColor: '#ba0e0eb5',
          customLogo: '/static/img/17-agustus/logo_ecal.svg'
        });
      } else {
        Object.assign(assets, {
          hasAssets: true,
          bgBottom: '/static/img/tenant/tripat/bottom.webp',
          cColor: 'rgba(255, 255, 255, 0.75)',
          bgTop: '/static/img/tenant/tripat/top.webp',
          logo: '/static/img/tenant/tripat/logo.webp',
          bgColor: '#acdce9'
        })
      }
      break;

		case 'selong-safemode':
			Object.assign(assets, {
				hasAssets: true,
				bgBottom: '/static/img/tenant/selong/bottom_bw.webp',
				cColor: 'rgba(255, 255, 255, 0.75)',
				bgTop: '/static/img/tenant/selong/top_bw.webp',
				logo: '/static/img/tenant/selong/logo_bw.webp',
				bgColor: '#c7f5ff',
				isSafe: true
			})
			break;

		case 'demo2':
		case 'demo':
			Object.assign(assets, {
				hasAssets: true,
				bgBottom: '/static/img/tenant/demo/bg_bottom.webp',
				bgOrder: 'tb',
				cColor: '#fff',
				bgTop: '/static/img/tenant/demo/bg_top.webp',
				logo: '/static/img/tenant/demo/logo.webp',
				logoTop: '/static/img/tenant/demo/kardex/logo_top.webp',
				bgBottomKardex: '/static/img/tenant/demo/kardex/bg_bottom.webp',
				bgTopKardex: '/static/img/tenant/demo/kardex/bg_top.webp',
				bgColorKardex: '#E3F4FC',
				isDemo: true
			});
			break;
	}
	return(assets);
};

const hasSpecialAssets= ()=> {
	const dateStart = Date.parse('08/12/2024');
	const dateEnd = Date.parse('08/20/2024');
	const now = Date.now();

	if (['stagingrsudselong', 'emrrsudsoedjono'].includes(getSubDomain())) {

		const dateStart = Date.parse('08/16/2024')
		const dateEnd = Date.parse('08/22/2024');

		return now >= dateStart && now < dateEnd
	}

	return now >= dateStart && now < dateEnd;
};