import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { authenticate, isDomain } from './utils';
import { OutlinedInput } from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import { getAssets } from './utils';
import './alert.css'

const assets= getAssets();

const styles = {
	form: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	title: {
		margin: '25px 0px'
	},
	titleDark: {
		margin: '25px 0px',
		color: '#fff'
	},
	inputContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: "0px 6px",
		margin: '10px 20px'
	},
	input: {
		flexGrow: 2,
		color: '#000000',
		backgroundColor: '#ffffff',
		'&:before': {
			borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
		},
		'&:after': {
			borderBottom: '1px solid #0073d1',
		},
		'&:hover:not(.MuiInput-disabled-47):not(.MuiInput-focused-46):not(.MuiInput-error-49):before': {
			borderBottom: '1px solid rgba(255, 255, 255)',
		},
		borderRadius: 20
	},
	inputDark: {
		flexGrow: 2,
		color: '#fff',
		backgroundColor: '#1f2a32',
		borderRadius: 18,
		'&:before': {
			borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
		},
		'&:after': {
			borderBottom: '1px solid #0073d1',
		},
		'&:hover:not(.MuiInput-disabled-47):not(.MuiInput-focused-46):not(.MuiInput-error-49):before': {
			borderBottom: '1px solid rgba(255, 255, 255)',
		},
		'& input:-webkit-autofill': {
			backgroundColor: 'transparent'
		},
		borderRadius: 20
	},
	focused: {
		backgroundColor: 'transparent !important'
	},
	submit: {
		width: '100%',
		borderRadius: 5,
		color: '#ffffff',
		backgroundColor: 
			process.env.REACT_APP_MODE === 'phr' ? '#0ebe7e' :
			process.env.REACT_APP_MODE === 'board' ? '#eb7744' :
			process.env.REACT_APP_MODE === 'incidental' ? 'rgba(1, 132, 109, 1)' :
			assets.hasAssets && assets.buttonColor ? assets.buttonColor : '#1ab7a7',
		'&:hover': {
			backgroundColor: 
				process.env.REACT_APP_MODE === 'phr' ? '#04a96d' :
				process.env.REACT_APP_MODE === 'board' ? '#da551a' :
				process.env.REACT_APP_MODE === 'incidental' ? 'rgba(1, 103, 82, 1)' :
				assets.hasAssets && assets.buttonHoverColor ? assets.buttonHoverColor : '#17a496'
		}
	},
	helperText: {
		'&:first-letter': {
			textTransform: 'capitalize'
		}
	},
	forgotPassword: {
		textAlign: 'right',
		fontSize: 12,
		margin: '8px 0px',
		color: '#083253',
		cursor: 'pointer'
	},
	submitContainer: {
		marginTop: 50,
		width: '100%',
		textAlign: 'center'
	},
	signUp: {
		fontSize: 12,
		marginTop: 15,
		display: 'flex',
		justifyContent: 'center'
	},
	textSignUp: {
		color: '#1ab7a7',
		fontSize: 12,
		marginLeft: 5,
		cursor: 'pointer'
	},
	iconContainer: {
		width: 79, 
		height: 79, 
		borderRadius: '50%', 
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center',
		margin: '20px 0px 50px'
	},
	alertRoot: {
		width: '100%', 
		display: 'flex', 
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	}
};

class SignIn extends Component {

	#isIncidental= process.env.REACT_APP_MODE === 'incidental';

	constructor(props) {
		super(props);

		const messageElement = document.getElementsByName("message");

		this.state = {
			username: '',
			password: '',
			rePassword: '',
			email: '',
			usernameError: null,
			passwordError: null,
			rePasswordError: null,
			emailError: null,
			helperText: messageElement[0] ? messageElement[0].getAttribute("content") : null,
			alertMessage: null,
			type: 'sign-in',
			form: 'general',
			showPassword: false
		}
	}

	handleChange = (name, value) => {
		this.setState({ [name]: value, [name+'Error']: null });
	}

	handleChangeInput = name => event => {
		this.handleChange(name, event.target.value);
	}

	handleLogin = () => {
		const { username, password } = this.state;
		authenticate(username, password);
	}

	resetForm = () => {
		this.setState({ 
			username: '',
			password: '',
			rePassword: '',
			email: '',
			usernameError: null,
			passwordError: null,
			rePasswordError: null,
			emailError: null, 
			helperText: null
		});
	}

	handleChangeType = (type) => () => {
		this.setState({ type });
		this.resetForm();
	}

	onSubmit = (event) => {
		event.preventDefault();

		let isValid = true;
		let unsetAttribute = [];
		if(this.state.type === 'sign-in'){
			if (this.state.username === '') {
				this.setState({ usernameError: true });
				unsetAttribute.push("Username");
				isValid = false;
			}
			if (this.state.password === '') {
				this.setState({ passwordError: true });
				unsetAttribute.push("Password");
				isValid = false;
			}
		} else if(this.state.type === 'sign-up'){
			if (this.state.username === '') {
				this.setState({ usernameError: true });
				unsetAttribute.push("Username");
				isValid = false;
			}
			if (this.state.password === '') {
				this.setState({ passwordError: true });
				unsetAttribute.push("Password");
				isValid = false;
			}
			if (this.state.rePassword === '') {
				this.setState({ rePasswordError: true });
				unsetAttribute.push("Re-Enter Password");
				isValid = false;
			}
			if (this.state.email === '') {
				this.setState({ emailError: true });
				unsetAttribute.push("Email");
				isValid = false;
			}
		} else {
			if (this.state.username === '') {
				this.setState({ usernameError: true });
				unsetAttribute.push("Username");
				isValid = false;
			}
		}
		if (isValid) {
			if(this.state.type === 'sign-in'){
				event.currentTarget.submit();
			} else if(this.state.type === 'sign-up'){
				this.resetForm();
				this.setState({form: 'success', type: 'sign-in', alertMessage: 'Registration Success'})
			} else {
				this.resetForm();
				this.setState({form: 'success', type: 'sign-in', alertMessage: 'Please Check Your Email'})
			}
		} else {
			this.setState({ helperText: (unsetAttribute.length === 2 ?
				unsetAttribute.join(" and ") :  
				unsetAttribute.map(x => x)) + " is required"
			})
		}
	}

	render() {
		const { classes } = this.props;
		// const titleClass= (this.#isIncidental) ? classes.titleDark: classes.title;
		const titleClass= classes.title;

		// const csrfName = document.getElementsByName("_csrf_name")[0].getAttribute("content");
		// const csrfToken = document.getElementsByName("_csrf_token")[0].getAttribute("content");
		const hash = window.location.hash.replace('#/', '');
		const pathname = window.location.pathname.replace('/', '');
		const queryParams = new URLSearchParams({
			...(hash ? { hash } : {}),
			...(pathname ? { pathname } : {})
		}).toString();
		if(this.state.form === 'general'){
			return (
				<form action={"login" + queryParams ? `?${queryParams}` : ""} method="post" onSubmit={this.onSubmit} className={classes.form}>
					{(assets.hasAssets) ? (
						<img
							style={{
								width: "80%", 
								margin: "6px 0 12px"
							}}
							src={assets.customLogo ? assets.customLogo : "static/img/logo_form_login.png"}
							alt="logo ecalyptus"
						/>
					): (
						<>
							{this.state.type === 'sign-in' ?
								<Typography className={titleClass}>Welcome, please <b>Sign In</b></Typography>:
							this.state.type === 'forgot-password' ?
								<Typography className={titleClass}>Did you forgotten your password?</Typography> :
								<Typography className={titleClass}>Welcome, please register</Typography>
								
							}
						</>
					)}
					{this.state.type === 'sign-in' ?
						<>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="username"
									name="username"
									placeholder="Username" 
									value={this.state.username} 
									onChange={this.handleChangeInput("username")} 
									// className={(this.#isIncidental) ? classes.inputDark: classes.input}
									className={classes.input}
									autoComplete={(this.#isIncidental) ? 'off': 'on'}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									classes={{focused:classes.focused}}
									variant="outlined"
									error={this.state.usernameError !== null}
								/>
								
							</div>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="password"
									name="password"
									placeholder="Password" 
									type={this.state.showPassword ? "text" : "password"}
									value={this.state.password} 
									onChange={this.handleChangeInput("password")}
									// className={(this.#isIncidental) ? classes.inputDark: classes.input}
									className={classes.input}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									endAdornment={
										<div onClick={() => this.setState({showPassword: !this.state.showPassword})}>
											{this.state.showPassword ?
												<VisibilityOff
													style={{
														cursor: 'pointer',
														color: '#bfbfbf'
													}}
												/> :
												<Visibility 
													style={{
														cursor: 'pointer',
														color: '#bfbfbf'
													}}
												/>
											}
											
										</div>
										
									}
									error={this.state.passwordError !== null}
								/>
							</div>
						</> : this.state.type === 'forgot-password' ?
						<>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="username"
									name="username"
									placeholder="Username/Email" 
									value={this.state.username} 
									onChange={this.handleChangeInput("username")} 
									className={classes.input}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									variant="outlined"
									error={this.state.usernameError !== null}
								/>
							</div>
						</> :
						<>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="username"
									name="username"
									placeholder="Username" 
									value={this.state.username} 
									onChange={this.handleChangeInput("username")} 
									className={classes.input}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									variant="outlined"
									error={this.state.usernameError !== null}
								/>
							</div>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="email"
									name="email"
									placeholder="Email" 
									type="email"
									value={this.state.email} 
									onChange={this.handleChangeInput("email")}
									className={classes.input}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									error={this.state.emailError !== null}
								/>
							</div>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="password"
									name="password"
									placeholder="Password" 
									type="password"
									value={this.state.password} 
									onChange={this.handleChangeInput("password")}
									className={classes.input}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									error={this.state.passwordError !== null}
								/>
							</div>
							<div className={classes.inputContainer}>
								<OutlinedInput 
									id="rePassword"
									name="rePassword"
									placeholder="Re-Enter Password" 
									type="password"
									value={this.state.rePassword} 
									onChange={this.handleChangeInput("rePassword")}
									className={classes.input}
									inputProps={{
										style: { padding: 13, height: 10 }
									}}
									error={this.state.rePasswordError !== null}
								/>
							</div>
						</>
					}
					
					<div className={classes.submitContainer}>
						{/*this.state.type === 'sign-in' &&
							<Typography 
								className={classes.forgotPassword} 
								onClick={this.handleChangeType('forgot-password')}
							>
								Forgot Password
							</Typography>
						*/}
						<input type="hidden" />
						<Button className={classes.submit} type="submit">
							{this.state.type === 'sign-in' ? 'Login' : 'Submit'}
						</Button>
						<Typography 
							variant='caption' 
							color='error' 
							align='center' 
							className={classes.helperText}
						>
							{this.state.helperText}
						</Typography>
						{/*<Typography className={classes.signUp}>
							Already have account? 
							{this.state.type === 'sign-in' ?
								<Typography 
									className={classes.textSignUp}
									onClick={this.handleChangeType('sign-up')}
								>
									Sign Up
								</Typography> :
								<Typography 
									className={classes.textSignUp}
									onClick={this.handleChangeType('sign-in')}
								>
									Login
								</Typography> 
							}
						</Typography>*/}
					</div>
				</form>
			)
		} else if(this.state.form === 'success'){
			return (
				<div className={classes.alertRoot}>
					<Typography 
						className={classes.title}
						style={{color: '#1ab7a7'}}
					>
						{this.state.alertMessage}
					</Typography>
					<div className={classes.iconContainer} style={{backgroundColor: '#1ab7a7'}}>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width={36}>
							<circle className="path circle" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeMiterlimit="10" cx="66" cy="66" r="58"/>
							<rect width={50} height={50} x={80} fill='#1ab7a7'/>
							<polyline className="path check" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="115.2,20.2 51.5,88.8 29.8,67.5 "/>
						</svg>
					</div>
					<Button className={classes.submit} onClick={() => this.setState({form: 'general'})}>
						Login
					</Button>
				</div>
			)
		} else {
			return (
				<div className={classes.alertRoot}>
					<Typography 
						className={classes.title}
						style={{color: '#fc0000'}}
					>
						{this.state.alertMessage}
					</Typography>
					<div className={classes.iconContainer} style={{backgroundColor: '#fc0000'}}>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width={36}>
						<circle className="path circle" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeMiterlimit="10" cx="66" cy="66" r="58"/>
							<line className="path line" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
							<line className="path line" fill="none" stroke="#FFFFFF" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
						</svg>
					</div>
					<Button className={classes.submit} onClick={() => this.setState({form: 'general'})}>
						Login
					</Button>
				</div>
			)
		}
	}
}

export default withStyles(styles)(SignIn);