import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Typography from '@material-ui/core/Typography';
import { BroadcastChannel } from 'broadcast-channel';
import { isDomain, getAssets } from './utils';

const channel = new BroadcastChannel('tab');

channel.addEventListener('message', (msg) => {
	if (msg && msg.type === 'another-tab-login') {
		window.location.reload();
	}
});

const assets = getAssets();

const styles = theme => ({
	root: {
		backgroundImage: 
			process.env.REACT_APP_MODE === 'phr' ? 'url(static/img/bg_palma.png)' :
			process.env.REACT_APP_MODE === 'board' ? 'url(static/img/bg_board.png)' :
			process.env.REACT_APP_MODE === 'incidental' ? 'url(static/img/bg_16082021.png)' :
			'url(static/img/bg_ecal.png)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: process.env.REACT_APP_MODE === 'board' ? '100%' : 800,
		backgroundPositionX: '55%',
		backgroundPositionY: 127,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100vh',
		transition: "background-size .3s",
		transitionTimingFunction: 'ease-in',
		[theme.breakpoints.down('md')]: {
			backgroundSize: 700,
			backgroundPositionY: 110,
		},
		[theme.breakpoints.down('sm')]: {
			backgroundSize: 600,
			backgroundPositionY: 125,
		}
	},
	rootNoBackground: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	rootAssets: {
		background: assets.bgOrder=='tb' ?  `url(${assets.bgTop}) left top / 100% auto no-repeat, url(${assets.bgBottom}) left bottom / 100% auto no-repeat, ${assets.bgColor}`: `url(${assets.bgBottom}) left bottom / 100% auto no-repeat, url(${assets.bgTop}) left top / 100% auto no-repeat, ${assets.bgColor}`,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100vh',
		filter: assets.isSafe ? "grayscale(1)" : "none"
	},
	logoContainer: {
		display: 'flex',
		alignItems: 'center',
		// height: 132,
	    padding: assets.hasAssets && assets.onlyLogo ? 25 : 15
	},
	logo: {
		maxWidth: process.env.REACT_APP_MODE==='board' ? 613:
			process.env.REACT_APP_MODE==='incidental' ? 398:
			isDomain('rsudl', 'rskb', 'rsds') ? 400 :
			200,
		height: 'auto',
	},
	logoAssets: {
		maxWidth: 400
	},
	panelContainer: {
		width: 300,
		minHeight: 385,
		display: 'flex',
		alignItems: 'flex-start',
		flexGrow: 2,
	    marginTop: assets.hasAssets && assets.onlyLogo ? 32 : 22
	},
	panel: {
		width: 'calc(100% - 90px)',
		padding: '20px 45px',
		backgroundColor: assets.hasAssets && assets.customBackground ? assets.customBackground : '#ffffff',
		borderRadius: 5,
		boxShadow: '0 4px 10px 0 #0058a166',
	},
	panelDark: {
		width: 'calc(100% - 90px)',
		padding: '20px 45px',
		// backgroundColor: '#2f3a49',
		backgroundColor: '#ffffff',
		borderRadius: 15,
		// boxShadow: '0 4px 10px 0 #000',
		boxShadow: '0 4px 10px 0 #0058a166',
	},
	poweredContainer: {
		height: 47,
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100vw'
	},
	poweredImg: {
		maxWidth: 391,
    	height: 'auto',
		marginLeft: 30
	},
	assetOnlyLogo: {
		backgroundImage: 
			'url(static/img/bg_ecal.png)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: process.env.REACT_APP_MODE === 'board' ? '100%' : 800,
		backgroundPositionX: '55%',
		backgroundPositionY: 127,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100vh',
		transition: "background-size .3s",
		transitionTimingFunction: 'ease-in',
		[theme.breakpoints.down('md')]: {
			backgroundSize: 700,
			backgroundPositionY: 110,
		},
		[theme.breakpoints.down('sm')]: {
			backgroundSize: 600,
			backgroundPositionY: 125,
		}
	},
	rootAssetsKardex: {
		background: `url(${assets.bgBottomKardex}) left bottom / 100% auto no-repeat, url(${assets.bgTopKardex}) left top / 100% auto no-repeat, ${assets.bgColorKardex}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: assets.isDemo ? 'flex-start' : 'center',
		flexDirection: 'column',
		height: '100vh'
	},
	panelContainerKardex: {
		width: 300,
		minHeight: 385,
		display: 'flex',
		alignItems: 'flex-start',
		flexGrow: 0.5,
	    marginTop: 22
	},
	logoContainerKardex: {
		display: 'flex',
		alignItems: 'center',
		// height: 132,
	    padding: assets.hasAssets && assets.onlyLogo ? 25 : 15
	},
});

const theme = createMuiTheme({
	shadows: Array(25).fill("none"),
	typography: {
		fontFamily: ['Roboto'].join(',')
	},
	overrides: {
		MuiPrivateNotchedOutline: {
			root: {
				borderRadius: 30
			}
		},
		MuiInputBase: {
			root: {
				fontSize: '0.9rem'
			}
		}
	}
});

class App extends Component {

	#isIncidental= process.env.REACT_APP_MODE === 'incidental';

	state = {
		view: 'sign-in',
		screenWidth: window.innerWidth,
		screenHeight: window.innerHeight
	}

	switchToSignIn = () => {
		this.setState({ view: 'sign-in' })
	}

	switchToSignUp = () => {
		this.setState({ view: 'sign-up' })
	}
	
	handleResize = () => {
		this.setState({
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
		});
	};

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}


  	render() {
		const { classes } = this.props;
		const {screenHeight, screenWidth} = this.state;
		const isKardex = screenHeight >= 1920  && screenHeight <= 2260 && screenWidth >= 1080 && screenWidth <= 1275;
		const isDemo = assets.isDemo || isDomain("demo") || isDomain("demo2");

		return (
			<MuiThemeProvider theme={theme}>
				<div className={this.#isIncidental ? classes.rootNoBackground: assets.hasAssets && assets.onlyLogo ? classes.assetOnlyLogo : assets.hasAssets ? (isKardex && !!assets.bgTopKardex && !!assets.bgBottomKardex ? classes.rootAssetsKardex : classes.rootAssets) : classes.root}>
					{this.#isIncidental &&
						<div style={{
							position: 'absolute',
							// backgroundColor: '#1c232e',
							backgroundImage: 'linear-gradient(to bottom, rgba(1, 132, 109, 1), rgba(1, 103, 82, 1))',
							zIndex: -1
						}}>
							<div style={{
								backgroundImage: 'url(static/img/bg_top_15122021_11.png)',
								backgroundSize: '100%',
								backgroundRepeat: 'no-repeat',
								width: '100vw',
								height: '100vh',
								position: 'absolute'
							}} />
							<div style={{
								backgroundImage: 'url(static/img/bg_bottom_15122021_11.png)',
								backgroundSize: '100%',
								backgroundRepeat: 'no-repeat',
								width: '100vw',
								height: '100vh',
								backgroundPosition: 'center bottom'
							}} />
						</div>
					}
					{/* Logo Top di login demo */}
					{isKardex && isDemo && (
						<div className={classes.logoContainerKardex} style={{
							flex: 0.2,
							alignItems: "flex-start",
							paddingTop: "3rem"
						}}>
							<img 
								alt="logo top"
								src={assets.logoTop} 
							/>
						</div>
					)}
					<div className={isKardex && !!assets.bgTopKardex && !!assets.bgBottomKardex ? classes.logoContainerKardex : classes.logoContainer}>
						{/* {!this.#isIncidental && */}
							<img 
								alt="background" 
								src={
									process.env.REACT_APP_MODE === 'phr' ? 'static/img/logo_palma.png' :
									process.env.REACT_APP_MODE === 'board' ? 'static/img/logo_board.png' :
									process.env.REACT_APP_MODE === 'incidental' ? 'static/img/logo_rsds_15122021.png' :
									assets.hasAssets ? assets.logo:
									isDomain('rsudl') ? 'static/img/logo_rsudl.png' :
									isDomain('rsds') ? 'static/img/rsds/logo.webp' :
									"static/img/logo_ecal.png"
								} 
								className={assets.hasAssets ? classes.logoAssets: classes.logo}
								style={{
									maxWidth: assets.hasAssets ? (isKardex && isDemo ? 300 : classes.logoAssets.maxWidth) : classes.logo.maxWidth
								}}
							/>
	  					{/* } */}
					</div> 
					<div className={isKardex && !!assets.bgTopKardex && !!assets.bgBottomKardex ? classes.panelContainerKardex : classes.panelContainer}>
						<Paper className={(this.#isIncidental) ? classes.panelDark: classes.panel}>
							{/* <Button onClick={this.switchToSignIn}>Sign in</Button>
							<Button onClick={this.switchToSignUp}>Sign up</Button> */}
							{this.state.view === 'sign-in' && <SignIn />}
							{this.state.view === 'sign-up' && <SignUp />}
						</Paper>
					</div>
					{process.env.REACT_APP_MODE === 'board' &&
						<div className={classes.poweredContainer}>
							<img 
								alt="powered by"
								src="static/img/powered_by.png"
								className={classes.poweredImg}
							/>
						</div>
	  				}
					{/* {isDomain('rsudl') &&
						<div style={{
							display: 'flex',
							justifyContent: 'flex-end',
							width: 'calc(100vw - 50px)'
						}}>
							<div style={{
								display: 'flex',
								flexDirection: 'column'
							}}>
								<img 
									alt="background" 
									src="static/img/logo-bsre.png" 
									style={{ 
										width: 175,
										marginLeft: 'auto'
									}}
								/>
								<span style={{ marginBottom : 10, fontSize : 12,  fontStyle: 'italic' }}>Aplikasi <b>Electronic Medical Record</b> ecalyptus sudah menggunakan Tanda Tangan Digital</span>
							</div>
						</div>
	  				} */}
					<Typography 
						variant='caption'
						align='center'
						style={{
							position: 'absolute',
							bottom: 25,
							color: assets.cColor
							// ...(isDomain('rskb') && {
							// 	color: '#fff'
							// })
						}}
					>
						Copyright {new Date().getFullYear()} PT. Buana Varia Komputama All Rights Reserved
					</Typography>
				</div>
			</MuiThemeProvider>
		);
	}
}

export default withStyles(styles)(App);

